import React, {Fragment, useEffect, useState} from "react";
import {Dialog, Transition} from '@headlessui/react'
import axios from "axios";

function InfoShortWord(props) {

    return (
        <div className="dark:text-white bg-white dark:bg-gray-900 text-gray-600 flex-1 w-full py-4 mx-auto md:px-8 px-5 border-black">
            <div className="max-w-4xl mx-auto pt-20">
                <div className="flex justify-between flex-col md:flex-row">
                    <h2 className="text-xl sm:text-4xl lg:text-4xl leading-none font-extrabold tracking-tight text-gray-900 dark:text-white">How does this generator work?</h2>
                    <a className="pt-6 md:pt-0" href="https://www.producthunt.com/posts/domainprinter?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-domainprinter" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=314060&theme=light" alt="Domainprinter - A clean and simple domain generator | Product Hunt" style={{width: "250px", height: "54px"}} width="250" height="54" /></a>
                </div>
                <br/>
                <div className="max-w-4xl sm:text-xl sm:leading-10 space-y-6 mb-6">
                    <p>
                        This is short domain name generator generates fake, short and pronounceable words. Most of the domains will be taken, but if you are lucky you
                        might catch a good word that is still available.
                    </p>
                </div>
                <br/>
                <br/>
            </div>
        </div>
    );
}

export default InfoShortWord;
