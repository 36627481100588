import Layout from "../components/layout";
import SEO from "../components/seo";
import React, {useState, useEffect, Fragment} from "react"
import axios from 'axios';
import DomainBox from "../components/domainBox";
import ReactLoading from "react-loading";
import {Listbox, Switch, Transition} from "@headlessui/react";
import {SelectorIcon} from "@heroicons/react/solid";
import InfoOneWord from "../components/infoOneWord";
import {createGlobalState} from "react-hooks-global-state";
import DomainBoxFake from "../components/domainBoxFake";
import InfoShortWord from "../components/infoShortWord";


const extensions = [
    {id: 1, name: '.com', unavailable: false},
    {id: 2, name: '.io', unavailable: false},
    {id: 3, name: '.net', unavailable: false},
    {id: 4, name: '.co', unavailable: false},
    {id: 5, name: '.xyz', unavailable: false}
]

const domainLengths = [
    {id: 3, name: '3', unavailable: false},
    {id: 4, name: '4', unavailable: false},
    {id: 5, name: '5', unavailable: false},
    {id: 6, name: '6', unavailable: false}
]

function IndexPage() {
    const listLocalStorage = typeof window !== 'undefined' ? localStorage.getItem("list") : "[]";
    let initial = JSON.parse(listLocalStorage);
    if (!initial || !Array.isArray(initial)) {
        initial = [];
    }
    const initialState = {favorites: initial};

    const {useGlobalState} = createGlobalState(initialState);
    const [favorites, setFavorites] = useGlobalState('favorites');

    const [extension, setExtension] = useState(extensions[0])
    const [domainLength, setDomainLength] = useState(domainLengths[2])
    const [isLoading, setIsLoading] = useState(false)

    const [counter, setCounter] = useState(1)
    const [results, setResults] = useState([])
    const [showTaken, setShowTaken] = useState(true)

    const createPost = (random = false) => {
        setIsLoading(true)
        setCounter(1)
        setResults([])
        axios.get(`.netlify/functions/hello-world?counter=` + 1 + "&extension=" + extension.id + "&generator=3" + "&length=" + domainLength.id).then(res => {
            setResults(res.data)
            setIsLoading(false)
        })
    }

    const fetchMoreResults = () => {
        setIsLoading(true)
        axios.get(`.netlify/functions/hello-world?counter=` + (counter + 1) + "&extension=" + extension.id + "&generator=3" + "&length=" + domainLength.id).then(res => {
            const joined = results.concat(res.data);
            setResults(joined)
            setIsLoading(false)
        })
    }

    useEffect(() => {
        const listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                event.preventDefault();
                createPost();
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, [counter]);

    return (
        <Layout favorites={favorites} setFavorites={setFavorites}>
            <SEO
                keywords={[`domain`, `name`, `generator`, `tool`]}
                title="Domainprinter: Available Short Domain Name Generator"
            />

            <section>
                <div className="sm:px-6 px-5 pb-16 dark:bg-gray-800 lg:max-w-4xl mx-auto">
                    <div className="sm:pl-4 container w-full mx-auto pb-16">
                        <div className="mb-5">
                            <h1 className="text-2xl text-4xl lg:text-5xl leading-none font-extrabold tracking-tight text-gray-900 dark:text-white mb-1 sm:mt-10">Short
                                Domain
                                Name Generator</h1>
                        </div>
                        <div className={"flex md:flex-row items-center mb-5 flex-col"}>
                            <p className="text-xl text-gray-600 dark:text-white">Discover pronounceable</p>
                            <p className="w-24 mx-4">
                                <Listbox value={domainLength} onChange={setDomainLength}>
                                    <div className="relative mt-5 md:mt-0">
                                        <Listbox.Button
                                            className="relative h-10 w-full py-2 pl-3 pr-10 text-left bg-gray-200 text-black rounded-lg cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm">
                                            <span className="block truncate">{domainLength.name}</span>
                                            <span
                                                className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                                      <SelectorIcon
                                                          className="w-5 h-5 text-gray-400"
                                                          aria-hidden="true"
                                                      />
                                                    </span>
                                        </Listbox.Button>
                                        <Transition
                                            as={Fragment}
                                            leave="transition ease-in duration-10"
                                            leaveFrom="opacity-100"
                                            leaveTo="opacity-0"
                                        >
                                            <Listbox.Options
                                                className="z-10 absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                {domainLengths.map((person, personIdx) => (
                                                    <Listbox.Option
                                                        key={personIdx}
                                                        className={({active}) =>
                                                            `${active ? 'bg-gray-200 text-amber-900' : 'text-gray-900'}
                              cursor-default dark:text-black select-none relative py-2 pl-3 pr-4`
                                                        }
                                                        value={person}
                                                    >
                                                        {({extension, active}) => (
                                                            <>
                          <span
                              className={`${
                                  extension ? 'font-medium' : 'font-normal'
                              } block truncate`}
                          >
                            {person.name}
                          </span>
                                                                {extension ? (
                                                                    <span
                                                                        className={`${
                                                                            active ? 'bg-gray-200 text-amber-600' : 'text-amber-600'
                                                                        }
                                    absolute inset-y-0 left-0 flex items-center pl-3`}
                                                                    >

                            </span>
                                                                ) : null}
                                                            </>
                                                        )}
                                                    </Listbox.Option>
                                                ))}
                                            </Listbox.Options>
                                        </Transition>
                                    </div>
                                </Listbox>
                            </p>
                            <p className="text-xl text-gray-600 dark:text-white text-center mt-5 md:mt-0">letter</p>
                            <p className="w-24 mx-4">
                                <Listbox value={extension} onChange={setExtension}>
                                    <div className="relative mt-5 md:mt-0">
                                        <Listbox.Button
                                            className="relative h-10 w-full py-2 pl-3 pr-10 text-left bg-gray-200 text-black rounded-lg cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm">
                                            <span className="block truncate">{extension.name}</span>
                                            <span
                                                className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                                      <SelectorIcon
                                                          className="w-5 h-5 text-gray-400"
                                                          aria-hidden="true"
                                                      />
                                                    </span>
                                        </Listbox.Button>
                                        <Transition
                                            as={Fragment}
                                            leave="transition ease-in duration-10"
                                            leaveFrom="opacity-100"
                                            leaveTo="opacity-0"
                                        >
                                            <Listbox.Options
                                                className="z-10 absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                {extensions.map((person, personIdx) => (
                                                    <Listbox.Option
                                                        key={personIdx}
                                                        className={({active}) =>
                                                            `${active ? 'bg-gray-200 text-amber-900' : 'text-gray-900'}
                              cursor-default dark:text-black select-none relative py-2 pl-3 pr-4`
                                                        }
                                                        value={person}
                                                    >
                                                        {({extension, active}) => (
                                                            <>
                          <span
                              className={`${
                                  extension ? 'font-medium' : 'font-normal'
                              } block truncate`}
                          >
                            {person.name}
                          </span>
                                                                {extension ? (
                                                                    <span
                                                                        className={`${
                                                                            active ? 'bg-gray-200 text-amber-600' : 'text-amber-600'
                                                                        }
                                    absolute inset-y-0 left-0 flex items-center pl-3`}
                                                                    >

                            </span>
                                                                ) : null}
                                                            </>
                                                        )}
                                                    </Listbox.Option>
                                                ))}
                                            </Listbox.Options>
                                        </Transition>
                                    </div>
                                </Listbox>
                            </p>
                            <p className="text-xl text-gray-600 dark:text-white text-center mt-5 md:mt-0">domains</p>
                        </div>
                        <div className="flex justify-center md:justify-start">
                            <button height={"30px"} onClick={() => createPost()}
                                    className="w-32 flex-none bg-gray-900 hover:bg-gray-700 text-white text-lg leading-6 font-semibold py-3 px-6 border border-transparent rounded-xl focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-gray-900 focus:outline-none transition-colors duration-200">
                                {isLoading ? <ReactLoading width="20px" style={{
                                    margin: "0 auto",
                                    fill: "white",
                                    width: "20px",
                                    height: "24px"
                                }} type={"spin"} color="#fff"/> : "Generate!"}
                            </button>
                        </div>
                    </div>
                    {
                        results && results.length > 0 &&
                        <div className="pt-2 pb-2 flex justify-start align-middle">
                            <Switch
                                checked={showTaken}
                                onChange={() => {
                                    if(showTaken) {
                                        setShowTaken(false)
                                    } else {
                                        setShowTaken(true)
                                    }
                                }}
                                className={`${
                                    showTaken ? 'bg-blue-600' : 'bg-gray-200'
                                } relative inline-flex items-center h-6 rounded-full w-11`}
                            >
                            <span
                                className={`${
                                    showTaken ? 'translate-x-6' : 'translate-x-1'
                                } inline-block w-4 h-4 transform bg-white dark:bg-gray-900 rounded-full`}
                            />
                            </Switch>
                            <span className="pl-1">Show taken domains</span>
                        </div>

                    }
                    <div className="mx-auto max-w-4xl grid grid-cols-1 md:grid-cols-3 gap-4 mt-1">
                        {results.map((result) => (
                            <>
                                <DomainBox showTaken={showTaken} favorites={favorites} setFavorites={setFavorites} showInfo={true}
                                           result={result}/>

                            </>
                        ))}
                        {results && results.length > 0 && results.length % 3 === 2 &&
                        <>
                            <DomainBoxFake/>
                        </>
                        }
                        {results && results.length > 0 && results.length % 3 === 1 &&
                        <>
                            <DomainBoxFake/>
                            <DomainBoxFake/>
                        </>
                        }
                        {results && results.length > 0 && <DomainBoxFake/>}
                        {results && results.length > 0 && <DomainBoxFake/>}
                        {results && results.length > 0 && <DomainBoxFake/>}
                    </div>

                    {results.length > 0 && <div className="pt-8 w-full flex justify-between">
                        <button
                            height={"50px"}
                            onClick={() => {
                                setCounter(counter + 1)
                                fetchMoreResults();
                            }}
                            className="w-56 mx-auto flex-none bg-gray-900 hover:bg-gray-700 text-white text-lg leading-6 font-semibold py-3 px-6 border border-transparent rounded-xl focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-gray-900 focus:outline-none transition-colors duration-200">
                            {isLoading ? <ReactLoading width="20px" style={{
                                margin: "0 auto",
                                fill: "white",
                                width: "20px",
                                height: "24px"
                            }} type={"spin"} color="#fff"/> : "Generate more"}
                        </button>
                    </div>}
                </div>
                <InfoShortWord/>
            </section>
        </Layout>
    );
}

export default IndexPage;
